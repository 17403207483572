import type { MaterialsModel } from "./materials.model";
import type { LineItemModel } from "./offer.model";

export interface MaterialApiResponseModel {
	readonly name: string;
	readonly prime_cost: number;
	readonly sku: string;
	readonly display_name: string;
	readonly brand: string | null;
	readonly price: number;
	readonly description: string;
	readonly price_with_margin_and_taxes: number;
	readonly material_type: string;
	readonly quantity: number;
}

export interface MaterialWithAssociatedMaterialsApiResponseModel
	extends MaterialApiResponseModel {
	readonly associated_materials: readonly MaterialApiResponseModel[];
}

export interface MaterialsApiResponseModel {
	readonly materials: readonly MaterialWithAssociatedMaterialsApiResponseModel[];
}

/**
 * Transform an API model to a Domain model
 * @param dto API response model
 * @returns Domain model
 */
export function fromDto(dto: MaterialsApiResponseModel): MaterialsModel {
	return {
		materials: {
			list: dto.materials.map((material) => material),
			suggestionTerms: dto.materials.map((material) => ({
				id: material.sku,
				name: material.display_name,
				type: material.material_type,
				sku: material.sku,
			})),
		},
	};
}

const calculatePriceWithoutTax = (
	priceWithTax: number,
	taxRate: number,
): number => {
	return priceWithTax / (1 + taxRate / 100);
};

const calculatePriceWithTax = (
	priceWithoutTax: number,
	taxRate: number,
): number => {
	return priceWithoutTax * (1 + taxRate / 100);
};

/**
 * Helper function to transform a single material to LineItemModel
 * @param material Material to transform
 * @param vat The applied VAT
 * @returns LineItemModel
 */
const mapToLineItem =
	(vat = 21) =>
	(material: MaterialApiResponseModel): LineItemModel => {
		let priceIncludingTaxes = material.price_with_margin_and_taxes;
		let priceExcludingTaxes = `${Number(material.price).toFixed(2)}`;
		if (vat === 7) {
			priceExcludingTaxes = `${Number(
				calculatePriceWithoutTax(material.price_with_margin_and_taxes, vat),
			).toFixed(2)}`;
		}
		if (vat === 10) {
			priceIncludingTaxes = calculatePriceWithTax(material.price, vat);
		}
		return {
			sku: material.sku,
			name: material.display_name,
			type: material.material_type,
			priceIncludingTaxes: `${Number(priceIncludingTaxes).toFixed(2)}`,
			priceExcludingTaxes,
			cost: `${Number(material.prime_cost).toFixed(2)}`,
			quantity: material.quantity || 1,
			subtotal: Number(
				(priceIncludingTaxes * (material.quantity || 1)).toFixed(2),
			),
		};
	};

/**
 * Converts a material and its associated materials to LineItemModel[]
 * @param material Material with associated materials
 * @param vat The applied VAT
 * @returns Array of LineItemModel
 */
export function toLineItemModel(
	material?: MaterialWithAssociatedMaterialsApiResponseModel,
	vat?: number,
): LineItemModel[] {
	if (!material) return [];

	const mapToLineItemWithVat = mapToLineItem(vat);

	// Main material line item
	const mainLineItem = mapToLineItemWithVat(material);

	// Associated materials line items
	const associatedLineItems = (material.associated_materials || []).map(
		mapToLineItemWithVat,
	);

	return [mainLineItem, ...associatedLineItems];
}
